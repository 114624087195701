
import { AlegeriStyled } from "./alegeri2024.styles";

const Alegeri2024 = () => {

	const files = [
		{
			'id': 33,
			'file_name': 'Proces-verbal-alegeri-turul-2+anexe.pdf',
		},
		{
			'id': 32,
			'file_name': 'Model_BuletindeVot_ReprezentantAG.pdf',
		},{
			'id': 31,
			'file_name': 'Model_BuletindeVot_Consiliu.pdf',
		},
		{
			'id': 30,
			'file_name': 'Model_BuletindeVot_Cenzori.pdf',
		},
		{
			'id': 29,
			'file_name': 'Anexa-3-Model-declaratie-reprezentant-in-Consiliul-national.pdf',
		},
		{
			'id': 28,
			'file_name': 'Decizie_CEC_nr.11_reprezentantCN.pdf',
		},
		{
			'id': 27,
			'file_name': 'ListaVotanti_CMHarghita.pdf',
		}, 
		{
			'id': 26,
			'file_name': 'Proces_verbal_Listefinale_candidati.pdf',
		}, 
		{
			'id': 25,
			'file_name': 'dr.Szöllösi_Attila.pdf',
		},
		{
			'id': 24,
			'file_name': 'dr.Biró_László.pdf',
		},
		{
			'id': 23,
			'file_name': 'dr.Domokos_Melinda.pdf',
		},
		{
			'id': 22,
			'file_name': 'dr.Fogarasi_Zoltán.pdf',
		},
		{
			'id': 21,
			'file_name': 'dr.Kacso_István_Lajos.pdf',
		},
		{
			'id': 20,
			'file_name': 'dr.Könczei_Csaba.pdf',
		},
		{
			'id': 19,
			'file_name': 'dr.Kovács_Emőke.pdf',
		},
		{
			'id': 18,
			'file_name': 'dr.Lakatos_Miklós_Zsolt.pdf',
		},
		{
			'id': 17,
			'file_name': 'dr.László_Zsuzsánna.pdf',
		},
		{
			'id': 16,
			'file_name': 'dr.Lőkös_Barna_Csaba.pdf',
		},
		{
			'id': 15,
			'file_name': 'dr.Ráduly_Dénes.pdf',
		},
		{
			'id': 14,
			'file_name': 'dr.Sinkó_János.pdf',
		},
		{
			'id': 13,
			'file_name': 'dr.Solti_Norbert.pdf',
		},
		{
			'id': 12,
			'file_name': 'dr.Suciu_Zsuzsánna.pdf',
		},
		{
			'id': 11,
			'file_name': 'dr.Szakács_Erőss_Dénes.pdf',
		},
		{
			'id': 10,
			'file_name': 'dr.Székely_Judit.pdf',
		},
		{
			'id': 9,
			'file_name': 'dr.Tubák_Nimród.pdf',
		},
		{
			'id': 8,
			'file_name': 'proces_verbal_candidaturi.pdf',
		},
		{
			'id': 7,
			'file_name': 'Anexa-2-Model-declaratie-candidatura.pdf',
		},
		{
			'id': 6,
			'file_name': 'Model_Declaratie_Candidatura.docx',
		},
		{
			'id': 5,
			'file_name': 'Prevederilegale_Conditii_Candidati.docx',
		},
		{
			'id': 4,
			'file_name': 'decizie CEC privind calendarul de activitati.pdf',
		},
		{
			'id': 3,
			'file_name': 'Decizie_NumireComisieElectoralăTeritorială.PDF',
		},
		{
			'id': 2,
			'file_name': 'Monitorul-Oficial-Partea-I-nr.-578Bis.pdf',
		},
		{
			'id': 1,
			'file_name': 'NotaDeInformare_GDPR_Anexa23.pdf',
		},

	];
	files.sort((a, b) => (a.id > b.id) ? 1 : -1)

	return (

		<div className="content_inner">
		<AlegeriStyled>
			<h1>Alegeri 2024</h1>

			<div>
				<h2>Stimați colegi,</h2>
		<p>
					Colegiul Teritorial Harghita anunţă organizarea alegerilor organelor de conducere și de control ale colegiului
		</p>
				<ul>
				<li>
						Consiliul Colegiului
				</li>
					<li>
						Reprezentanții în Adunarea Generală Națională
					</li>
					<li>
						Comisia de cenzori
					</li>
				</ul>
				
<p>
					Alegerile se vor desfășura în zilele:
</p>
				<h3>20-21 septembrie 2024, în intervalul orar 10.00-20.00 (Turul I)</h3>
				<h3>4-5 octombrie 2024, în intervalul orar 10.00-20.00 (Turul II)</h3>
				<p>Turul II va avea loc în cazul în care în primul tur al alegerilor nu se întrunește condiția de cvorum de 2/3 din numărul membrilor Colegiului Teritorial Harghita, cu drept de vot.</p>
					<p>Candidaturile pentru funcțiile de membru în consiliul colegiului teritorial, de reprezentant în Adunarea generală națională și de membru în comisia de cenzori <b>se depun în perioada 16 august 2024 -  3 septembrie 2024, inclusiv.</b></p>
					<p>Candidatura pentru funcția de reprezentant în Consiliul național poate fi depusă de orice medic, membru al Colegiului teritorial Harghita, chiar dacă nu a fost ales membru în Consiliul Colegiului sau reprezentant în Adunarea generală.
					Perioada de depunere va fi începând cu 20 septembrie 2024 până la data la care expiră perioada de depunere a candidaturilor pentru funcția de membru în biroul consiliului Colegiului.</p>
					<p>Detalii privind condițiile, modelul declarației de candidatură și alte documente necesare le găsiți la <b>Fișiere descărcabile</b>.</p>
					
				
				

				<h3>Alegerile se vor desfășura în format hibrid:</h3>
				<ul>
				
					<li><b>fizic</b> (la sediul Colegiului, Miercurea Ciuc, str. Harghita, nr. 6) și</li>
					<li><b>prin vot electronic</b>, prin <b>aplicaţia medicului</b>, dezvoltată de Colegiul Medicilor din România şi pusă la dispoziţia tuturor membrilor săi </li>
				</ul>
				<h3><a href="/alegeri2024_files/proces_verbal+lista_candidati.pdf" target = "_blank" className="highlighted">Proces_verbal+lista_candidati.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Biró_László.pdf" target = "_blank" className="highlighted">Anexa_4_Biró_László.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Solti_Norbert.pdf" target = "_blank" className="highlighted">Anexa_4_Solti_Norbert.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Szöllösi_Attila.pdf" target = "_blank" className="highlighted">Anexa_4_Szöllösi_Attila.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Ráduly_Dénes.pdf" target = "_blank" className="highlighted">Anexa_4_Ráduly_Dénes.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Lőkös_Barna_Csaba.pdf" target = "_blank" className="highlighted">Anexa_4_Lőkös_Barna_Csaba.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_4_Könczei_Csaba.pdf" target = "_blank" className="highlighted">Anexa_4_Könczei_Csaba.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Anexa_3_Sinkó_János.pdf" target = "_blank" className="highlighted">Anexa_3_Sinkó_János.pdf</a></h3>
				<h3><a href="/alegeri2024_files/ListaVotanti_Turul_II.pdf" target = "_blank" className="highlighted">Lista votanți turul II.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Proces-verbal-alegeri-turul-2+anexe.pdf" target = "_blank" className="highlighted">Proces verbal alegeri turul 2 + anexe.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Proces_verbal_rezultate_finale.pdf" target = "_blank" className="highlighted">Proces verbal rezultate finale.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Decizie_nr_54_validare_Harghita.pdf" target = "_blank" className="highlighted">Decizie nr.54 validare Harghita.pdf</a></h3>
				<h3><a href="/alegeri2024_files/Decizie CEC nr. 15 (1).pdf" target = "_blank" className="highlighted">Decizie CEC nr. 15.pdf</a></h3>



					<h4>LISTA	FINALĂ A CANDIDAȚILOR PENTRU FUNCȚIA DE MEMBRU
						&Icirc;N COMISIA DE CENZORI A COLEGIULUI TERITORIAL HARGHITA</h4>
					
					
					
					<table width="100%" cellpadding="7" cellspacing="1" border="1px"><colgroup><col width="24" /><col width="176" /><col width="130" /><col width="153" /></colgroup><tbody><tr valign="top"><td width="24" height="25"><p align="left">Nr.<br />
						crt.</p>
					</td>
						<td width="176"><p align="left">Nume
							și Prenume</p>
						</td>
						<td width="130"><p align="left">Specialitate</p>
						</td>
						<td width="153"><p align="left">Grad
							profesional</p>
						</td>
					</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">1</p>
						</td>
							<td width="176"><p align="left">Domokos
								Melinda- M&aacute;ria</p>
							</td>
							<td width="130"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="153"><p align="justify">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">2</p>
						</td>
							<td width="176"><p align="left">Kov&aacute;cs
								Emőke</p>
							</td>
							<td width="130"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="153"><p align="justify">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="5"><p align="left">3</p>
						</td>
							<td width="176"><p align="left">Sz&eacute;kely
								Judit</p>
							</td>
							<td width="130"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="153"><p align="justify">specialist</p>
							</td>
						</tr>
					</tbody></table>
					


					<h4>LISTA
						FINALĂ A CANDIDAȚILOR PENTRU FUNCȚIA DE MEMBRU &Icirc;N CONSILIUL
						TERITORIAL HARGHITA</h4>
					
					<table width="100%" border="1px" cellpadding="7" cellspacing="0"><colgroup><col width="24" /><col width="176" /><col width="160" /><col width="165" /></colgroup><tbody><tr valign="top"><td width="24" height="25"><p align="left">Nr.<br />
						crt.</p>
					</td>
						<td width="176"><p align="left">Nume
							și Prenume</p>
						</td>
						<td width="160"><p align="left">Specialitate</p>
						</td>
						<td width="165"><p align="left">Grad
							profesional</p>
						</td>
					</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">1</p>
						</td>
							<td width="176"><p align="left">Bir&oacute;
								L&aacute;szl&oacute;</p>
							</td>
							<td width="160"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">2</p>
						</td>
							<td width="176"><p align="left">Fogarasi
								Zolt&aacute;n</p>
							</td>
							<td width="160"><p align="left">Medicină
								internă</p>
							</td>
							<td width="165"><p align="justify">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">3</p>
						</td>
							<td width="176"><p align="left">Kacs&oacute;
								Istv&aacute;n Lajos</p>
							</td>
							<td width="160"><p align="left">Chirurgie
								generală</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">4</p>
						</td>
							<td width="176"><p align="left">K&ouml;nczei
								Csaba</p>
							</td>
							<td width="160"><p align="left">Chirurgie
								generală</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">5</p>
						</td>
							<td width="176"><p align="left">Lakatos
								Mikl&oacute;s Zsolt</p>
							</td>
							<td width="160"><p align="left">Medicină
								de urgență</p>
							</td>
							<td width="165"><p align="left">specialist</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">6</p>
						</td>
							<td width="176"><p align="left">L&aacute;szl&oacute;
								Zsuzs&aacute;nna</p>
							</td>
							<td width="160"><p align="left">Medicină
								internă</p>
							</td>
							<td width="165"><p align="justify">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">7</p>
						</td>
							<td width="176"><p align="left">Lők&ouml;s
								Barna Csaba</p>
							</td>
							<td width="160"><p align="left">Chirurgie
								generală</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">8</p>
						</td>
							<td width="176"><p align="left">R&aacute;duly
								D&eacute;nes</p>
							</td>
							<td width="160"><p align="left">Anestezie
								și terapie intensivă</p>
							</td>
							<td width="165"><p align="left">specialist</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">9</p>
						</td>
							<td width="176"><p align="left">Sink&oacute;
								J&aacute;nos</p>
							</td>
							<td width="160"><p align="left">Medicină
								legală</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">10</p>
						</td>
							<td width="176"><p align="left">Solti
								Norbert</p>
							</td>
							<td width="160"><p align="left">Neurochirurgie</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">11</p>
						</td>
							<td width="176"><p align="left">Suciu
								Zsuzs&aacute;nna</p>
							</td>
							<td width="160"><p align="left">Medicină
								internă și Cardiologie</p>
							</td>
							<td width="165"><p align="left">Med.internă
								&ndash;primar</p>
								<p align="left">Cardiologie
									-specialist</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">12</p>
						</td>
							<td width="176"><p align="left">Szak&aacute;cs
								Erőss Dionisie</p>
							</td>
							<td width="160"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="165"><p align="left">specialist</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">13</p>
						</td>
							<td width="176"><p align="left">Sz&ouml;ll&ouml;si
								Attila</p>
							</td>
							<td width="160"><p align="left">Urologie</p>
							</td>
							<td width="165"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="5"><p align="left">14</p>
						</td>
							<td width="176"><p align="left">Tub&aacute;k
								Nimr&oacute;d</p>
							</td>
							<td width="160"><p align="left">Anestezie
								și terapie intensivă</p>
							</td>
							<td width="165"><p align="left">specialist</p>
							</td>
						</tr>
					</tbody></table>
					

				
					<h4>LISTA
						FINALĂ A CANDIDAȚILOR PENTRU FUNCȚIA DE	REPREZENTANT ÎN ADUNAREA GENERALĂ NAȚIONALĂ
					DIN	PARTEA COLEGIULUI TERITORIAL HARGHITA</h4>
					<table width="100%" border="1px" cellpadding="7" cellspacing="0"><colgroup><col width="24" /><col width="176" /><col width="130" /><col width="153" /></colgroup><tbody><tr valign="top"><td width="24" height="25"><p align="left">Nr.<br />
						crt.</p>
					</td>
						<td width="176"><p align="left">Nume
							și Prenume</p>
						</td>
						<td width="130"><p align="left">Specialitate</p>
						</td>
						<td width="153"><p align="left">Grad
							profesional</p>
						</td>
					</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">1</p>
						</td>
							<td width="176"><p align="left">Bir&oacute;
								L&aacute;szl&oacute;</p>
							</td>
							<td width="130"><p align="left">Medicină
								de familie</p>
							</td>
							<td width="153"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">2</p>
						</td>
							<td width="176"><p align="left">Lők&ouml;s
								Barna Csaba</p>
							</td>
							<td width="130"><p align="left">Chirurgie
								generală</p>
							</td>
							<td width="153"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">3</p>
						</td>
							<td width="176"><p align="left">Sink&oacute;
								J&aacute;nos</p>
							</td>
							<td width="130"><p align="left">Medicină
								legală</p>
							</td>
							<td width="153"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="6"><p align="left">4</p>
						</td>
							<td width="176"><p align="left">Sz&ouml;ll&ouml;si
								Attila</p>
							</td>
							<td width="130"><p align="left">Urologie</p>
							</td>
							<td width="153"><p align="left">primar</p>
							</td>
						</tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr></tr>
						<tr valign="top"><td width="24" height="5"><p align="left">5</p>
						</td>
							<td width="176"><p align="left">Tub&aacute;k
								Nimr&oacute;d</p>
							</td>
							<td width="130"><p align="left">Anestezie
								și terapie intensivă</p>
							</td>
							<td width="153"><p align="left">specialist</p>
							</td>
						</tr>
					</tbody></table>
					
					

					
					
			</div>
				<h2>Fișiere descărcabile</h2>
			{files.map((file) => 
				
				<p>	
					<a href={process.env.PUBLIC_URL + '/alegeri2024_files/' + file.file_name} target="_blank" rel="noreferrer">{file.file_name}</a> 
				</p>
				
				)}


			<h2>Aplicaţia utilizată pentru accesul la votul electronic:</h2>

			<p>- Accesul la votul electronic se va face prin aplicaţia medicului, dezvoltată de Colegiul Medicilor din România şi pusă la dispoziţia tuturor membrilor săi.</p>
	
				<p>- Detaliile necesare privind instalarea/utilizarea aplicaţiei medicului sunt disponibile pe site-ul official <a target="_blank" rel="noreferrer" href="https://www.cmr.ro">www.cmr.ro</a></p>

			<p>- Instalarea/Utilizarea aplicaţiei medicului va necesita parcurgerea următorilor paşi:</p>
			<ol type="a">
				<li>descărcarea aplicaţiei pe telefonul mobil (iOS & Android);</li>
				<li>înregistrarea utilizatorului (crearea contului de utilizator pe baza informaţiilor înregistrate şi verificate din baza de date a Colegiului Medicilor din România);</li>
				<li>autentificarea utilizatorului (cu nume de utilizator şi parolă).</li>
			</ol>
			<p>- Instalarea aplicaţiei medicului se va face prealabil desfăşurării procesului electoral, pentru a permite derularea procedurilor de actualizare a datelor medicului.</p>
			</AlegeriStyled>
		</div>
	)
}

export default Alegeri2024;